@import url(https://fonts.googleapis.com/css2?family=Alex+Brush&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Niconne&display=swap);
body {
  margin: 0;
  background-color: #090905;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  font-family: serif
}

html {
  scroll-behavior: smooth;
}

/* @media (min-width: 2000px) {
  .wrapper {
    position: absolute;
    max-width: 1920px;
    margin: 0 auto;
  }
} */

::selection {
  background: rgba(82, 208, 114, 0.234);
}

@font-face {
  font-family: Galanthia;
  src: url(/static/media/Galanthia-Regular.4ae716c6.ttf);
}

.App {
  background-color: "#1C2013";
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

a, a:visited {
  color: #F5FFB9;
  text-decoration: none;
  cursor: pointer;
}

.content-menu-wrapper {
  /* display: flex; */

  /* flex-direction: row-reverse; */
}

.content {
  position: relative;
  /* width: 100% !important; */

}

section {
  margin-right: auto;
  margin-left: auto;
}

.l-screen-menu-wrapper {
  display: none;
  position: inherit;
  width: 30%;
  max-width: 400px;
  height:100%;
}

.l-screen-menu {
  position:fixed;
  width: 30%;
  height:100vh;
  /* background-color: #1C2013; */
  /* opacity: 30%; */
}


h1,h2,p {
  margin: 0;
}

@media (min-width: 900px) {
  .l-screen-menu-wrapper {
    display:block;
  }

  .content-menu-wrapper {
    flex-direction: row-reverse;
  }
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
/* } * */

/* @media (min-width: 2000px) {
  .App {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
  }
} */


button {
  cursor: pointer;
}
/* Typography Related */
.main {
    position: relative;
    height: 100vh;
    max-height: 1080px;
    width: 100vw;
    max-width: 1920px;
    
}


#word_div_editable {
    position: relative;
    transition: all 1s ease-out;
    margin-bottom: 30px;
    width: 100vw;
}

.word_div {
    display: flex;
    flex-direction: column;
    transition: 'opacity 0.5s';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 0;
}

.hidden {
    -webkit-transform: translateY(-30px) !important;
            transform: translateY(-30px) !important;
    opacity: 0%;
}

.mark_main {
    position: relative;
    color: #fff684;
    font-family: Galanthia;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 7em;
    display: block;
    -webkit-filter: drop-shadow(5px 5px 10px #000);
            filter: drop-shadow(5px 5px 10px #000);
}

.jane_main {
    position:relative;
    /* left: 7vw; */
    /* margin-top: -0.3em; */
    /* margin-bottom: 0.7em; */
    color: #fff684;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Galanthia;
    font-size: 7em;
    /* font-style: normal; */
    font-weight: 400;
    line-height: normal; 
    /* left: 50vw; */
    /* transform: translate(-43vw, 0%); */
    display: block;
    /* position: relative; */
    -webkit-filter: drop-shadow(5px 5px 10px #000);
            filter: drop-shadow(5px 5px 10px #000);
}

.and_symbol {
    display: block;
    /* position: relative; */
    /* transform: translate(-43vw, 0%); */
    /* left: 43vw; */
    color: #3E4929;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Niconne;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-filter: drop-shadow(1px 1px 4px #595959);
            filter: drop-shadow(1px 1px 4px #595959);
}


@media (max-width: 650px) {
    .mark_main {
        font-size:5.5em;
    }

    .jane_main {
        font-size:5.5em;
    }

    .and_symbol {
        font-size: 32px;
    }
}

@media (max-width: 460px) {
    .mark_main {
        /* font-size:3.5em; */
    }

    .jane_main {
        /* font-size:3.5em; */
    }

    .and_symbol {
        /* font-size: 28px; */
    }
}

/* Background Related */
.background-div {
    position: relative;
    width: 100%;
    height: 100%;
}

.background-black, .background-photo, .background-color, .background-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.background-photo {
    /* background-image: url(../../assets/photos/mainPhoto.jpg); */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(/static/media/mainPhoto.cc77de16.jpg), lightgray 50% / cover no-repeat;
    background-position: center;
    background-size: cover;
}   

.background-black {
    background-color: #0E130C;
    opacity: 40%
}


.background-color {
    background-color: #0E130C;
    mix-blend-mode: color;
}

.background-gradient {
    background: linear-gradient(359deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80.71%);
}

/* Date & Button Related */

#main_date_button {
    position: relative;
    transition: all 1s ease-out;
}

#main_date_button p {
    /* position:absolute; */
    margin: 0;
    display: block;
    color: rgba(226, 235, 177, 1);
    font-size: 1em;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 1em;

}

.main_scrolldown_sign {
    margin-top: 1em;
}

.main_scrolldown_arrow {
    margin: 0 auto;
    background-image: url(/static/media/arrowdown.cb98a91f.svg);
    background-position: center;
    height: 44px;
    width: 44px;
    background-size: contain;

    -webkit-animation: mover ease-in-out 1s infinite  alternate;
    animation: mover ease-in-out 1s infinite  alternate;
}

@-webkit-keyframes mover {
    0% { -webkit-transform: translateY(0px); transform: translateY(0px); }
    100% { -webkit-transform: translateY(5px); transform: translateY(5px); }
}

@keyframes mover {
    0% { -webkit-transform: translateY(0px); transform: translateY(0px); }
    100% { -webkit-transform: translateY(5px); transform: translateY(5px); }
}

.main_scrolldown_text {
    margin-bottom: 0 !important;
    cursor: pointer;
}

.main_scrolldown_text:hover {
    opacity: .6;
}


.main_location_button:hover {
    background: #878964
}

.main_location_button {
    border: none;
    background: #F5FFB9;
    color: #232818;
    cursor: pointer;
    font-family: inherit;
    font-weight: 600;
    font-size: 1.2em;
    padding: 0.5em 1em;
    border-radius: 5px;
    outline: none;
    margin-bottom: 1.5em;
}


@media (max-width: 520px) {
    .main_location_button {
        /* font-size: 0.8em; */
        background-color: #f4ffb907;
        color:#F5FFB9;
        border:#F5FFB9 solid;
        border-width: 0.01em;
    }
}


.about-us_section-wrapper {
    position: relative;
    width: 100vw;
    /* max-width: 1920px; */
    /* padding-bottom: 2em; */
    background-color: #0f140c;
    /* height: 100vh; */
    /* background-image: url(/src/assets/emerald-background.png); */
    /* background-repeat: repeat; */
    /* background-position: left top; */
}

/* @media (min-width: 900px) {
    .about-us_section-wrapper {
        width: 70vw;
    }
} */

.about-us_div-wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding-bottom: 5em;
}

.about-us_floral-decoration {
    position:absolute;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    
    background-image: url(/static/media/botanic1-huge.ce61bcc2.png);
    pointer-events: none;
    opacity: 0.3;
    z-index: 0;
}

.about-us_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    align-self: stretch;
    padding: 3em 2em;
    padding-top: 5em;
}

.about-us_line {
    width: 100%;
    height: 1px;
    background: #EBEAC3;
}

.about-us_title {
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    letter-spacing: .1em;
    color: #EBEAC3;
    font-size: 3em;
    line-height: normal;
    white-space: nowrap;
}

@media (max-width: 470px) {
    .about-us_title {
        font-size: 2em;
    }
}

@media (max-width: 350px) {
    .about-us_title {
        font-size: 1.3em;
    }
}

.about-us_card span {
    display:block;
    color:#EBEAC3;
    font-size: 1.1em;
    
}

.about-us_card {
    display: flex;
    flex-direction: column;
    padding: 2em;
    grid-gap:8px;
    gap:8px;
}

@media (max-width:390px) {
    .about-us_card {
        padding-left: initial;
        padding-right: initial;
    }

    .about-us_info {
        justify-content: space-between;
    }
}

.about-us_info {
    display: flex;
    justify-content:space-around;
    margin-top: 1.4em;
    /* margin-bottom: 2em; */
}

/* "ABOUT US" SECTION'S MAIN CONTENT */
.about-us_first-flex {

    margin: 0 2em;
}


img {
    flex-shrink: none;
    display: block;
    max-width: 100%;
    max-height: 400px;
    width: auto;
    height: auto;
    object-fit: cover;
    object-position: top;
    z-index:3;
}
.about-us_first-text {
    flex: 1 1;
    /* text-align: justify; */
    text-align: start;
    color:#EBEAC3;
    font-size: 1em;
    letter-spacing: .04em;
}

.about-us_first-photo {
    /* margin :0 auto; */
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
}

.about-us_2pht {
    flex: 1 1;
}

.about-us_1pht {
    flex: 1 1;
}

.about-us_2pht {
    display: none;
}



@media(min-width: 900px) {
    img {
        max-width: 100%;
        height: auto;
        max-height: 400px;
    }

    .about-us_first-photo {
        flex: 1 1;
        margin :0 auto;
        position: flex;
        flex-direction: row;
        /* position */
        /* background-color:aliceblue; */
        /* background: url('../../assets/photos/aboutUsPhoto.jpg'); */
        /* background-position: center; */
        /* background-size: 100%; */
        /* width: 300px; */
        /* height: 300px; */
        /* position: relative; */
        /* padding-bottom: 30vh; */
    }
    


}
.gallery-main-wrapper {
    position: relative;
    width: 100%;
    /* max-width:1920px; */
    /* height: 100vh; */
    /* height: 700px; */
    background-color: #151c12;
    padding-bottom: 4em;

}

.gallery-content-wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

.gallery_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    align-self: stretch;
    padding: 3em 2em;
    padding-top: 5em;
}

@media (min-width: 900px) {
    .gallery-main-wrapper {
        /* width: 70vw; */
    }
}

.gallery_line {
    width: 100%;
    height: 1px;
    background: #EBEAC3;
}

.gallery_title {
    color: #EBEAC3;
    font-size: 3em;
    line-height: normal;
    white-space: nowrap;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    letter-spacing: .1em;
}

@media (max-width: 470px) {
    .gallery_title {
        font-size: 2em;
    }
}

@media (max-width: 350px) {
    .gallery_title {
        font-size: 1.3em;
    }
}



.gallery-viewer {
    display: inline-grid;
    /* flex-direction: row; */
    /* position: relative; */
    margin-left: 2em;
}

.gallery-pic {
    border-radius: 1em;
    margin: 0;
    /* width: 300px;
    height: 300px; */
}

.gallery-a {
    border-radius: 1em;
    max-width: 310px;
}

.gallery-item {
    margin: 5px;
}

.img-responsive {
    position: relative;
    overflow: clip;
    border-radius: 20px;
    max-width: 250px;
    /* margin-bottom: 1em; */
}

/* .img-responsive > * {
    z-index: 100%;
}
.img-responsive::after {
    content: "";
    position: absolute;
    display:block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    opacity: 1; 
*/


.lg-react-element {
    display: flex;

    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-row-gap: 2em;
    row-gap: 2em;
    flex-wrap:wrap;
    justify-content: space-between;
}

@media (max-width: 1040px) {
    .lg-react-element {
        margin: 0 2em;
        
    }
    .img-responsive {
        max-width: 210px;
    }
        
}

@media (max-width: 944px) {
    .lg-react-element {
        /* display: grid; */
        /* grid-template-columns: 1fr 1fr 1fr 1fr; */
        /* justify-items: center; */
        grid-row-gap: 1em;
        row-gap: 1em;
        grid-column-gap: 1em;
        -webkit-column-gap: 1em;
                column-gap: 1em;
        justify-content: space-evenly;
        /* place-items: center; */
    }
}

@media (max-width: 500px) {
    .img-responsive {
        max-width: 140px;
    }

}

@media (max-width: 380px) {
    .img-responsive {
        max-width: 120px;
    }
}

@media (max-width: 340px) {
    .img-responsive {
        max-width: 200px;
    }

    .sm-hide {
        display:none;
    }
}

@media (max-width: 800px) {
    .gallery-decoration {
        position:absolute;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        background-image: url(/static/media/botanic2-huge.819e764a.png);
        pointer-events: none;
        opacity: 0.3;
        z-index: 0;
    }
}
.location_content {
    margin: 0 2em;
    margin-top: -2em;
    margin-bottom: 5em;
}


.location_content_section {
    max-width:1100px;
    margin: 0 auto;
    padding-bottom: 5em;
}

#location {
    width: 100vw;
    /* max-width:1920px; */
    padding-bottom: 2em;
    background-color: #0f140c;
    /* height: 100% !important; */
}


.location-photo-img {
    position: relative;
    margin: 0 auto;
    width: 100%;
    object-position: center;
    -webkit-filter: drop-shadow(5px 5px 10px #000);
            filter: drop-shadow(5px 5px 10px #000);

}

.location_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    align-self: stretch;
    padding: 3em 2em;
    padding-top: 5em;
}

.location_map_button-wrapper {
    display: flex;
    flex-direction: column;
}

.map_button {
    display: flex;
    flex-direction: row;
    grid-gap: 2px;
    gap: 2px;
    text-decoration: none;
    background-color: #EBEAC3;
    border: none;
    border-radius: 2px;
    margin-top: 1em;
    cursor: pointer;
}

.map_button a {
    display: block;
    color: rgb(44, 50, 32) !important;
    padding: 6px;
    font-size: 1.4em;
}

.map_button-pin-icon {
    position: relative;
    top: 7px;
    display: block;
    background-image: url(/static/media/locationpin.8e31df7f.svg);
    width: 20px;
    height: 20px;
    object-fit: cover;
    background-size:cover;
    
}
hr {
    opacity: 0.4;
    width: 70%;
}
.location_line {
    width: 100%;
    height: 1px;
    background: #EBEAC3;
}

.location_title {
    color: #EBEAC3;
    font-size: 3em;
    line-height: normal;
    white-space: nowrap;
    letter-spacing: .1em;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

@media (max-width: 470px) {
    .location_title {
        font-size: 2em;
    }
}

@media (max-width: 350px) {
    .location_title {
        font-size: 1.3em;
    }
}

.location_map-wrapper {
    margin-top: 4em !important;
    display:flex;
    grid-gap: 1.5em;
    gap: 1.5em;
    flex-direction: column;
    
}

.location-address {
    flex: initial;
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
    
}

.location-address span{
    color:#EBEAC3;

}

.location_map {
    flex: initial;
    position: relative;
    width: 100%;
    min-height: 200px;
    height: auto;
    z-index: 2;
    /* margin: 0 auto; */
    flex: 1 1

}

.location-background {
    /* display: block; */
    margin: 0 auto;
    /* z-index: 0; */
    /* width: 70vw; */
    position: relative;
    /* right:0; */
    top: 20%;
}

.location-background span {
    color: #FFFED8;
    opacity: 0.2;
    font-weight: bold;
    font-size: 100px;
    white-space: nowrap;
}

@media (min-width: 1200px) {
    #location {
        /* height: 105vh; */
    }

    .location_map-wrapper {
        flex-direction: row;
        grid-gap: 1em;
        gap: 1em;
    }

    .location-map {
        flex: 1 1;
    }

    .location-address {
        flex: 1 1;
    }

    .location-photo-img {
        margin: 0 auto;
        position:relative;
        /* width: 900px; */
        /* right: 10px; */
    }
}


@media (max-width: 650px) {
    .location-background {
        top: 13%;
    }

    .location-background span {
        font-size: 70px;
    }
}

.marriage-advice-section-wrapper {
    position: relative;
    width: 100vw;
    /* max-width: 1920px; */
    background-color: #151c12;
    padding-bottom: 5em;
    
}

.marriageadvice-messagefromus p {
    font-size: 1.4em;
}

.marriageadvice-content-wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

.marriageadvice_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    align-self: stretch;
    padding: 3em 2em;
    padding-top: 5em;
}

.marriageadvice_line {
    width: 100%;
    height: 1px;
    background: #EBEAC3;
}

.marriageadvice_title {
    color: #EBEAC3;
    font-size: 3em;
    line-height: normal;
    white-space: nowrap;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    letter-spacing: .1em;
}

@media (max-width: 470px) {
    .marriageadvice_title {
        font-size: 2em;
    }
}

@media (max-width: 350px) {
    .marriageadvice_title {
        font-size: 1.3em;
    }
}

#adviceForm {
    position:relative;
    display:flex;
    flex-direction: column;
}

.marriageadvice-messagefromus p {
    color:#EBEAC3;
    font-size: 1em;
    letter-spacing: 0.04em;
    text-align: left;
}



/* Form Styling */

.marriageadvice-main-content {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
    margin:0 2em;
}
input, textarea {
    position: relative;
    margin-bottom: 1.5em;
    background-color: #11160f;
    border: #EBEAC3 solid;
    border-width: 0.5px;
    color:#EBEAC3;
    padding: 1em;     
    font-size: 1em;
    font-family: 'Montserrat';
    border-radius: 2px;
    resize: none;
    
    /* border:#0a0b07; */
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #EBEAC3;
    opacity: 0.4;
}

input::placeholder, textarea::placeholder {
    color: #EBEAC3;
    opacity: 0.4;
}

textarea {
    min-height: 150px;
    height: auto;
}

textarea:focus {
    outline: none !important;
    border: #EBEAC3 solid;
    border-width: 1px;
}
.form-name {
    background-color: #11160f;
    border: none;
    color:#EBEAC3;
    border: #EBEAC3 solid;
    border-width: 0.5px;
    /* padding: 1em;  */
}

.form-name:focus {
    outline: none !important;
    border: #EBEAC3 solid;
    border-width: 1px;
}

.form-button {
    border: #EBEAC3 solid;
    border-width: 0.5px;
    width: auto;
    position: relative;
    cursor: pointer;
    padding: 1em auto;
}

.form-button:hover {
    background-color: #EBEAC3;
    color:#11160f;
}


/* Background Styling */
.marriageadvice-background {
    position: absolute;
    background: url(/static/media/flower2.617d7d8b.png);
    pointer-events: none;

    /* width: 100%; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-size: cover; */
    background-repeat: no-repeat;
    /* background-position: center; */
}

@media (min-width: 900px) {
    .marriage-advice-section-wrapper {
        /* width: 70vw; */
    }

    .marriageadvice-main-content {
        flex-direction: row;
        
    }

    .marriageadvice-messagefromus {
        flex:1 1;

    }
    .marriageadvice-form-wrapper {
        flex:1 1;
    }

    #adviceForm{
        /* flex-direction: row; */
    }
}




#message {
    background-color: #0f140c;
    padding-bottom: 5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.message-content-wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

.message_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    align-self: stretch;
    padding: 3em 2em;
    padding-top: 5em;
}

.message_line {
    width: 100%;
    height: 1px;
    background: #EBEAC3;
}

.message_title {
    color: #EBEAC3;
    font-size: 3em;
    line-height: normal;
    white-space: nowrap;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    letter-spacing: .2em;
}

@media (max-width: 590px) {
    .message_title {
        font-size: 1.7em;
    }
}

@media (max-width: 350px) {
    .message_title {
        display: none;
    }
}

.message-border {
    position: relative;
    background: #0f140c;
    padding: 2rem 1rem;
    border: dashed 1px #afae98;
    max-width: 800px;
    margin: 0 auto;
}


.message_main p {
    /* padding-bottom: 1em; */
    color: #EBEAC3;
    font-size: 1em;
}


/* .message_border {
    padding: 2rem 1rem;
    border: 1rem solid #ebeac3;
    border-image: repeating-linear-gradient(45deg, transparent, transparent 5px, #ebeac3 6px, #ebeac3 15px, transparent 16px, transparent 20px) 20/1rem;
} */


.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2em 0;
}

.footer-mailbutton {
    background-color: #f4ffb907;
    color:#F5FFB9;
    cursor: pointer;
    font-family: inherit;
    font-weight: 600;
    font-size: 1em;
    padding: 0.5em 1em;
    border-radius: 5px;
    outline: none;
    /* margin-bottom: 1.5em; */
    border:#F5FFB9 solid;
    border-width: 0.01em;
}

.footer-mailbutton:hover {
    background: #878964
}

.footer-devname {
    margin-bottom: 0.5em;
    opacity: 0.3;
}

.footer-content span {
    color:#F5FFB9;
    opacity:0.3;
    font-size: .8em;
}

.footer-line {
    width: 100%;
    max-width: 300px;
    height: 0.4px;
    background: #EBEAC3;
    margin: 1em auto;
}
.loading-wheel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust the height to fit your layout */
  }
  
  .loading-wheel {
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
