.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2em 0;
}

.footer-mailbutton {
    background-color: #f4ffb907;
    color:#F5FFB9;
    cursor: pointer;
    font-family: inherit;
    font-weight: 600;
    font-size: 1em;
    padding: 0.5em 1em;
    border-radius: 5px;
    outline: none;
    /* margin-bottom: 1.5em; */
    border:#F5FFB9 solid;
    border-width: 0.01em;
}

.footer-mailbutton:hover {
    background: #878964
}

.footer-devname {
    margin-bottom: 0.5em;
    opacity: 0.3;
}

.footer-content span {
    color:#F5FFB9;
    opacity:0.3;
    font-size: .8em;
}

.footer-line {
    width: 100%;
    max-width: 300px;
    height: 0.4px;
    background: #EBEAC3;
    margin: 1em auto;
}