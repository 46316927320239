.marriage-advice-section-wrapper {
    position: relative;
    width: 100vw;
    /* max-width: 1920px; */
    background-color: #151c12;
    padding-bottom: 5em;
    
}

.marriageadvice-messagefromus p {
    font-size: 1.4em;
}

.marriageadvice-content-wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

.marriageadvice_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding: 3em 2em;
    padding-top: 5em;
}

.marriageadvice_line {
    width: 100%;
    height: 1px;
    background: #EBEAC3;
}

.marriageadvice_title {
    color: #EBEAC3;
    font-size: 3em;
    line-height: normal;
    white-space: nowrap;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    letter-spacing: .1em;
}

@media (max-width: 470px) {
    .marriageadvice_title {
        font-size: 2em;
    }
}

@media (max-width: 350px) {
    .marriageadvice_title {
        font-size: 1.3em;
    }
}

#adviceForm {
    position:relative;
    display:flex;
    flex-direction: column;
}

.marriageadvice-messagefromus p {
    color:#EBEAC3;
    font-size: 1em;
    letter-spacing: 0.04em;
    text-align: left;
}



/* Form Styling */

.marriageadvice-main-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin:0 2em;
}
input, textarea {
    position: relative;
    margin-bottom: 1.5em;
    background-color: #11160f;
    border: #EBEAC3 solid;
    border-width: 0.5px;
    color:#EBEAC3;
    padding: 1em;     
    font-size: 1em;
    font-family: 'Montserrat';
    border-radius: 2px;
    resize: none;
    
    /* border:#0a0b07; */
}

input::placeholder, textarea::placeholder {
    color: #EBEAC3;
    opacity: 0.4;
}

textarea {
    min-height: 150px;
    height: auto;
}

textarea:focus {
    outline: none !important;
    border: #EBEAC3 solid;
    border-width: 1px;
}
.form-name {
    background-color: #11160f;
    border: none;
    color:#EBEAC3;
    border: #EBEAC3 solid;
    border-width: 0.5px;
    /* padding: 1em;  */
}

.form-name:focus {
    outline: none !important;
    border: #EBEAC3 solid;
    border-width: 1px;
}

.form-button {
    border: #EBEAC3 solid;
    border-width: 0.5px;
    width: auto;
    position: relative;
    cursor: pointer;
    padding: 1em auto;
}

.form-button:hover {
    background-color: #EBEAC3;
    color:#11160f;
}


/* Background Styling */
.marriageadvice-background {
    position: absolute;
    background: url(../../assets/decoration/flower2.png);
    pointer-events: none;

    /* width: 100%; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-size: cover; */
    background-repeat: no-repeat;
    /* background-position: center; */
}

@media (min-width: 900px) {
    .marriage-advice-section-wrapper {
        /* width: 70vw; */
    }

    .marriageadvice-main-content {
        flex-direction: row;
        
    }

    .marriageadvice-messagefromus {
        flex:1;

    }
    .marriageadvice-form-wrapper {
        flex:1;
    }

    #adviceForm{
        /* flex-direction: row; */
    }
}

