/* Typography Related */
.main {
    position: relative;
    height: 100vh;
    max-height: 1080px;
    width: 100vw;
    max-width: 1920px;
    
}


#word_div_editable {
    position: relative;
    transition: all 1s ease-out;
    margin-bottom: 30px;
    width: 100vw;
}

.word_div {
    display: flex;
    flex-direction: column;
    transition: 'opacity 0.5s';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
}

.hidden {
    transform: translateY(-30px) !important;
    opacity: 0%;
}

.mark_main {
    position: relative;
    color: #fff684;
    font-family: Galanthia;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 7em;
    display: block;
    filter: drop-shadow(5px 5px 10px #000);
}

.jane_main {
    position:relative;
    /* left: 7vw; */
    /* margin-top: -0.3em; */
    /* margin-bottom: 0.7em; */
    color: #fff684;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Galanthia;
    font-size: 7em;
    /* font-style: normal; */
    font-weight: 400;
    line-height: normal; 
    /* left: 50vw; */
    /* transform: translate(-43vw, 0%); */
    display: block;
    /* position: relative; */
    filter: drop-shadow(5px 5px 10px #000);
}

.and_symbol {
    display: block;
    /* position: relative; */
    /* transform: translate(-43vw, 0%); */
    /* left: 43vw; */
    color: #3E4929;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: Niconne;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    filter: drop-shadow(1px 1px 4px #595959);
}


@media (max-width: 650px) {
    .mark_main {
        font-size:5.5em;
    }

    .jane_main {
        font-size:5.5em;
    }

    .and_symbol {
        font-size: 32px;
    }
}

@media (max-width: 460px) {
    .mark_main {
        /* font-size:3.5em; */
    }

    .jane_main {
        /* font-size:3.5em; */
    }

    .and_symbol {
        /* font-size: 28px; */
    }
}

/* Background Related */
.background-div {
    position: relative;
    width: 100%;
    height: 100%;
}

.background-black, .background-photo, .background-color, .background-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.background-photo {
    /* background-image: url(../../assets/photos/mainPhoto.jpg); */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(../../assets/photos/mainPhoto.jpg), lightgray 50% / cover no-repeat;
    background-position: center;
    background-size: cover;
}   

.background-black {
    background-color: #0E130C;
    opacity: 40%
}


.background-color {
    background-color: #0E130C;
    mix-blend-mode: color;
}

.background-gradient {
    background: linear-gradient(359deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 80.71%);
}

/* Date & Button Related */

#main_date_button {
    position: relative;
    transition: all 1s ease-out;
}

#main_date_button p {
    /* position:absolute; */
    margin: 0;
    display: block;
    color: rgba(226, 235, 177, 1);
    font-size: 1em;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 1em;

}

.main_scrolldown_sign {
    margin-top: 1em;
}

.main_scrolldown_arrow {
    margin: 0 auto;
    background-image: url(../../assets/svg/arrowdown.svg);
    background-position: center;
    height: 44px;
    width: 44px;
    background-size: contain;

    -webkit-animation: mover ease-in-out 1s infinite  alternate;
    animation: mover ease-in-out 1s infinite  alternate;
}

@-webkit-keyframes mover {
    0% { transform: translateY(0px); }
    100% { transform: translateY(5px); }
}

@keyframes mover {
    0% { transform: translateY(0px); }
    100% { transform: translateY(5px); }
}

.main_scrolldown_text {
    margin-bottom: 0 !important;
    cursor: pointer;
}

.main_scrolldown_text:hover {
    opacity: .6;
}


.main_location_button:hover {
    background: #878964
}

.main_location_button {
    border: none;
    background: #F5FFB9;
    color: #232818;
    cursor: pointer;
    font-family: inherit;
    font-weight: 600;
    font-size: 1.2em;
    padding: 0.5em 1em;
    border-radius: 5px;
    outline: none;
    margin-bottom: 1.5em;
}


@media (max-width: 520px) {
    .main_location_button {
        /* font-size: 0.8em; */
        background-color: #f4ffb907;
        color:#F5FFB9;
        border:#F5FFB9 solid;
        border-width: 0.01em;
    }
}