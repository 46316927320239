body {
  margin: 0;
  background-color: #090905;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  font-family: serif
}

html {
  scroll-behavior: smooth;
}

/* @media (min-width: 2000px) {
  .wrapper {
    position: absolute;
    max-width: 1920px;
    margin: 0 auto;
  }
} */

::selection {
  background: rgba(82, 208, 114, 0.234);
}
