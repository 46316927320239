.loading-wheel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust the height to fit your layout */
  }
  
  .loading-wheel {
    width: 50px; /* Adjust the size as needed */
    height: 50px; /* Adjust the size as needed */
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }