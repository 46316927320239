.location_content {
    margin: 0 2em;
    margin-top: -2em;
    margin-bottom: 5em;
}


.location_content_section {
    max-width:1100px;
    margin: 0 auto;
    padding-bottom: 5em;
}

#location {
    width: 100vw;
    /* max-width:1920px; */
    padding-bottom: 2em;
    background-color: #0f140c;
    /* height: 100% !important; */
}


.location-photo-img {
    position: relative;
    margin: 0 auto;
    width: 100%;
    object-position: center;
    filter: drop-shadow(5px 5px 10px #000);

}

.location_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding: 3em 2em;
    padding-top: 5em;
}

.location_map_button-wrapper {
    display: flex;
    flex-direction: column;
}

.map_button {
    display: flex;
    flex-direction: row;
    gap: 2px;
    text-decoration: none;
    background-color: #EBEAC3;
    border: none;
    border-radius: 2px;
    margin-top: 1em;
    cursor: pointer;
}

.map_button a {
    display: block;
    color: rgb(44, 50, 32) !important;
    padding: 6px;
    font-size: 1.4em;
}

.map_button-pin-icon {
    position: relative;
    top: 7px;
    display: block;
    background-image: url(../../assets/svg/locationpin.svg);
    width: 20px;
    height: 20px;
    object-fit: cover;
    background-size:cover;
    
}
hr {
    opacity: 0.4;
    width: 70%;
}
.location_line {
    width: 100%;
    height: 1px;
    background: #EBEAC3;
}

.location_title {
    color: #EBEAC3;
    font-size: 3em;
    line-height: normal;
    white-space: nowrap;
    letter-spacing: .1em;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

@media (max-width: 470px) {
    .location_title {
        font-size: 2em;
    }
}

@media (max-width: 350px) {
    .location_title {
        font-size: 1.3em;
    }
}

.location_map-wrapper {
    margin-top: 4em !important;
    display:flex;
    gap: 1.5em;
    flex-direction: column;
    
}

.location-address {
    flex: initial;
    display: flex;
    flex-direction:column;
    align-items: flex-start;
    gap: 10px;
    
}

.location-address span{
    color:#EBEAC3;

}

.location_map {
    flex: initial;
    position: relative;
    width: 100%;
    min-height: 200px;
    height: auto;
    z-index: 2;
    /* margin: 0 auto; */
    flex: 1

}

.location-background {
    /* display: block; */
    margin: 0 auto;
    /* z-index: 0; */
    /* width: 70vw; */
    position: relative;
    /* right:0; */
    top: 20%;
}

.location-background span {
    color: #FFFED8;
    opacity: 0.2;
    font-weight: bold;
    font-size: 100px;
    white-space: nowrap;
}

@media (min-width: 1200px) {
    #location {
        /* height: 105vh; */
    }

    .location_map-wrapper {
        flex-direction: row;
        gap: 1em;
    }

    .location-map {
        flex: 1;
    }

    .location-address {
        flex: 1;
    }

    .location-photo-img {
        margin: 0 auto;
        position:relative;
        /* width: 900px; */
        /* right: 10px; */
    }
}


@media (max-width: 650px) {
    .location-background {
        top: 13%;
    }

    .location-background span {
        font-size: 70px;
    }
}
