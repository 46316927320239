

#message {
    background-color: #0f140c;
    padding-bottom: 5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.message-content-wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

.message_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding: 3em 2em;
    padding-top: 5em;
}

.message_line {
    width: 100%;
    height: 1px;
    background: #EBEAC3;
}

.message_title {
    color: #EBEAC3;
    font-size: 3em;
    line-height: normal;
    white-space: nowrap;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    letter-spacing: .2em;
}

@media (max-width: 590px) {
    .message_title {
        font-size: 1.7em;
    }
}

@media (max-width: 350px) {
    .message_title {
        display: none;
    }
}

.message-border {
    position: relative;
    background: #0f140c;
    padding: 2rem 1rem;
    border: dashed 1px #afae98;
    max-width: 800px;
    margin: 0 auto;
}


.message_main p {
    /* padding-bottom: 1em; */
    color: #EBEAC3;
    font-size: 1em;
}


/* .message_border {
    padding: 2rem 1rem;
    border: 1rem solid #ebeac3;
    border-image: repeating-linear-gradient(45deg, transparent, transparent 5px, #ebeac3 6px, #ebeac3 15px, transparent 16px, transparent 20px) 20/1rem;
} */

