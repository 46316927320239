

.about-us_section-wrapper {
    position: relative;
    width: 100vw;
    /* max-width: 1920px; */
    /* padding-bottom: 2em; */
    background-color: #0f140c;
    /* height: 100vh; */
    /* background-image: url(/src/assets/emerald-background.png); */
    /* background-repeat: repeat; */
    /* background-position: left top; */
}

/* @media (min-width: 900px) {
    .about-us_section-wrapper {
        width: 70vw;
    }
} */

.about-us_div-wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding-bottom: 5em;
}

.about-us_floral-decoration {
    position:absolute;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    
    background-image: url(../../assets/decoration/botanic1-huge.png);
    pointer-events: none;
    opacity: 0.3;
    z-index: 0;
}

.about-us_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding: 3em 2em;
    padding-top: 5em;
}

.about-us_line {
    width: 100%;
    height: 1px;
    background: #EBEAC3;
}

.about-us_title {
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    letter-spacing: .1em;
    color: #EBEAC3;
    font-size: 3em;
    line-height: normal;
    white-space: nowrap;
}

@media (max-width: 470px) {
    .about-us_title {
        font-size: 2em;
    }
}

@media (max-width: 350px) {
    .about-us_title {
        font-size: 1.3em;
    }
}

.about-us_card span {
    display:block;
    color:#EBEAC3;
    font-size: 1.1em;
    
}

.about-us_card {
    display: flex;
    flex-direction: column;
    padding: 2em;
    gap:8px;
}

@media (max-width:390px) {
    .about-us_card {
        padding-left: initial;
        padding-right: initial;
    }

    .about-us_info {
        justify-content: space-between;
    }
}

.about-us_info {
    display: flex;
    justify-content:space-around;
    margin-top: 1.4em;
    /* margin-bottom: 2em; */
}

/* "ABOUT US" SECTION'S MAIN CONTENT */
.about-us_first-flex {

    margin: 0 2em;
}


img {
    flex-shrink: none;
    display: block;
    max-width: 100%;
    max-height: 400px;
    width: auto;
    height: auto;
    object-fit: cover;
    object-position: top;
    z-index:3;
}
.about-us_first-text {
    flex: 1;
    /* text-align: justify; */
    text-align: start;
    color:#EBEAC3;
    font-size: 1em;
    letter-spacing: .04em;
}

.about-us_first-photo {
    /* margin :0 auto; */
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.about-us_2pht {
    flex: 1;
}

.about-us_1pht {
    flex: 1;
}

.about-us_2pht {
    display: none;
}



@media(min-width: 900px) {
    img {
        max-width: 100%;
        height: auto;
        max-height: 400px;
    }

    .about-us_first-photo {
        flex: 1;
        margin :0 auto;
        position: flex;
        flex-direction: row;
        /* position */
        /* background-color:aliceblue; */
        /* background: url('../../assets/photos/aboutUsPhoto.jpg'); */
        /* background-position: center; */
        /* background-size: 100%; */
        /* width: 300px; */
        /* height: 300px; */
        /* position: relative; */
        /* padding-bottom: 30vh; */
    }
    


}