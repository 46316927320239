@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Niconne&display=swap');
@font-face {
  font-family: Galanthia;
  src: url(/src/assets/fonts/Galanthia-Regular.ttf);
}

.App {
  background-color: "#1C2013";
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
}

a, a:visited {
  color: #F5FFB9;
  text-decoration: none;
  cursor: pointer;
}

.content-menu-wrapper {
  /* display: flex; */

  /* flex-direction: row-reverse; */
}

.content {
  position: relative;
  /* width: 100% !important; */

}

section {
  margin-right: auto;
  margin-left: auto;
}

.l-screen-menu-wrapper {
  display: none;
  position: inherit;
  width: 30%;
  max-width: 400px;
  height:100%;
}

.l-screen-menu {
  position:fixed;
  width: 30%;
  height:100vh;
  /* background-color: #1C2013; */
  /* opacity: 30%; */
}


h1,h2,p {
  margin: 0;
}

@media (min-width: 900px) {
  .l-screen-menu-wrapper {
    display:block;
  }

  .content-menu-wrapper {
    flex-direction: row-reverse;
  }
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
/* } * */

/* @media (min-width: 2000px) {
  .App {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
  }
} */


button {
  cursor: pointer;
}