.gallery-main-wrapper {
    position: relative;
    width: 100%;
    /* max-width:1920px; */
    /* height: 100vh; */
    /* height: 700px; */
    background-color: #151c12;
    padding-bottom: 4em;

}

.gallery-content-wrapper {
    max-width: 1100px;
    margin: 0 auto;
}

.gallery_head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding: 3em 2em;
    padding-top: 5em;
}

@media (min-width: 900px) {
    .gallery-main-wrapper {
        /* width: 70vw; */
    }
}

.gallery_line {
    width: 100%;
    height: 1px;
    background: #EBEAC3;
}

.gallery_title {
    color: #EBEAC3;
    font-size: 3em;
    line-height: normal;
    white-space: nowrap;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    letter-spacing: .1em;
}

@media (max-width: 470px) {
    .gallery_title {
        font-size: 2em;
    }
}

@media (max-width: 350px) {
    .gallery_title {
        font-size: 1.3em;
    }
}



.gallery-viewer {
    display: inline-grid;
    /* flex-direction: row; */
    /* position: relative; */
    margin-left: 2em;
}

.gallery-pic {
    border-radius: 1em;
    margin: 0;
    /* width: 300px;
    height: 300px; */
}

.gallery-a {
    border-radius: 1em;
    max-width: 310px;
}

.gallery-item {
    margin: 5px;
}

.img-responsive {
    position: relative;
    overflow: clip;
    border-radius: 20px;
    max-width: 250px;
    /* margin-bottom: 1em; */
}

/* .img-responsive > * {
    z-index: 100%;
}
.img-responsive::after {
    content: "";
    position: absolute;
    display:block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    opacity: 1; 
*/


.lg-react-element {
    display: flex;

    /* grid-template-columns: 1fr 1fr 1fr; */
    row-gap: 2em;
    flex-wrap:wrap;
    justify-content: space-between;
}

@media (max-width: 1040px) {
    .lg-react-element {
        margin: 0 2em;
        
    }
    .img-responsive {
        max-width: 210px;
    }
        
}

@media (max-width: 944px) {
    .lg-react-element {
        /* display: grid; */
        /* grid-template-columns: 1fr 1fr 1fr 1fr; */
        /* justify-items: center; */
        row-gap: 1em;
        column-gap: 1em;
        justify-content: space-evenly;
        /* place-items: center; */
    }
}

@media (max-width: 500px) {
    .img-responsive {
        max-width: 140px;
    }

}

@media (max-width: 380px) {
    .img-responsive {
        max-width: 120px;
    }
}

@media (max-width: 340px) {
    .img-responsive {
        max-width: 200px;
    }

    .sm-hide {
        display:none;
    }
}

@media (max-width: 800px) {
    .gallery-decoration {
        position:absolute;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        background-image: url(../../assets/decoration/botanic2-huge.png);
        pointer-events: none;
        opacity: 0.3;
        z-index: 0;
    }
}